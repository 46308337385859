<template>
  <div class="home">
    <full-page :options="options" ref="fullPageRef">
      <div id="content">
        <div class="section section1" :style="bgImageVars">
          <div class="ideaText">
            <span data-text="创">创</span>
            <span data-text="新">新</span>
            <span data-text="致">致</span>
            <span data-text="远">远</span>
            <span data-text="求">求</span>
            <span data-text="实">实</span>
            <span data-text="至">至</span>
            <span data-text="极">极</span>
          </div>
          <div class="ideaTextEn">
            INNOVATION TO THE EXTREME AND PRACTICAL
          </div>
        </div>
        <div class="section section2">
          <div class="aboutTitles">
            <ul>
              <li>
                <div class="logoDiv">
                  <img src="@/assets/image/qszy1024.png" />
                </div>
                <h2>关于我们</h2>
              </li>
              <li>
                <div class="icons">
                  <i class="el-icon-d-arrow-right"></i>
                </div>
              </li>
              <li>
                <div class="liner">
                  <p class="p1"><a @click="handleClickAboutUsAdvantageFun">
                    {{ page2Data.titles[0] ? page2Data.titles[0].title : '核心算法'}}
                  </a></p>
                  <p class="p2">
                    {{ page2Data.titles[0] ? page2Data.titles[0].subTitle : '无监督学习、自动建模、自主命名、云-端协同'}}
                  </p>
                </div>
              </li>
              <li>
                <div class="icons">
                  <i class="el-icon-d-arrow-right"></i>
                </div>
              </li>
              <li>
                <div class="liner">
                  <p class="p1"><a @click="handleClickAboutUsAdvantageFun">
                    {{ page2Data.titles[1] ? page2Data.titles[1].title : '专业团队'}}
                  </a></p>
                  <p class="p2">
                    {{ page2Data.titles[1] ? page2Data.titles[0].subTitle : '专业合作资源、顶级顾问团队、业内自身技术团队'}}
                  </p>
                </div>
              </li>
              <li>
                <div class="icons">
                  <i class="el-icon-d-arrow-right"></i>
                </div>
              </li>
              <li>
                <div class="liner">
                  <p class="p1"><a @click="handleClickAboutUsAdvantageFun">
                    {{ page2Data.titles[2] ? page2Data.titles[2].title : '企业荣誉'}}
                  </a></p>
                  <p class="p2">
                    {{ page2Data.titles[2] ? page2Data.titles[0].subTitle : '省部级技术发明一等奖、省部级科技进步二等奖、创新创业大赛二等奖'}}
                  </p>
                </div>
              </li>
            </ul>
          </div>
          <div class="aboutContent">
            <div class="contents">
              <p v-for="(p, index) in page2Data.texts" :key="index">
                {{ p }}
              </p>
              <router-link to="/companyProfile">
                了解更多
              </router-link>
            </div>
            <div class="aboutImg">
              <el-carousel trigger="click" height="380px">
                <el-carousel-item
                  v-for="(image, key) in page2Data.images"
                  :key="key"
                >
                  <img
                    v-if="image.type === 'image'"
                    alt="about"
                    :src="image.url"
                  />
                  <video v-else width="100%" height="300" controls loop :autoplay="false">
                    <source :src="image.url" />
                  </video>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
        </div>
        <div class="section section3">
          <h1 class="moduleTitle">我们的优势</h1>
          <div class="advantage" >
            <ul>
              <li v-for="list in page3Data" :key="list.pageId">
                <div class="advantageBg"></div>
                <div class="advantageMain">
                  <h2>
                    <a v-if="list.pageId === 5" @click="handleClickAboutUsAdvantageFun">{{ list.pageName }}</a>
                    <a v-else-if="list.pageId === 6" @click="handleClickAboutUsAdvantageFun">{{ list.pageName }}</a>
                    <a v-else @click="handleClickAboutUsAdvantageFun">{{ list.pageName }}</a>
                  </h2>
                  <p>{{ list.pageId === 5 ? 'CORE ALGORITHM' : list.pageId === 6 ? 'CORE ALGORITHM' : 'PROFESSIONAL TEAM'}}</p>
                  <div :class="list.pageId === 5 ? 'algorithmImg' : list.pageId === 6 ? 'algorithmImgTeam': 'algorithmImgHonor'">
                    <div
                      :class="list.pageId === 5 ? 'algorithmImgItem' : list.pageId === 6 ? 'algorithmImgTeamItem': 'algorithmImgHonorItem'"
                      v-for="image in list.images"
                      :key="image.imageId+image.imageTitle"
                    >
                      <div class="itemImg">
                        <img :src="image.imageUrl" />
                      </div>
                      <div v-if="list.pageId === 6" class="itemText">
                        <h4>{{ image.imageTitle }}</h4>
                      </div>
                      <h4 v-else>{{ image.imageTitle }}</h4>
                    </div>
                  </div>
                </div>
              </li>


<!--              <li>-->
<!--                <div class="advantageBg"></div>-->
<!--                <div class="advantageMain">-->
<!--                  <h2><a @click="handleClickAboutUsAdvantageFun">核心算法</a></h2>-->
<!--                  <p>CORE ALGORITHM</p>-->
<!--                  <div class="algorithmImg">-->
<!--                    <div class="algorithmImgItem">-->
<!--                      <div class="itemImg">-->
<!--                        <img src="@/assets/image/homeCode1.png" />-->
<!--                      </div>-->
<!--                      <h4>无监督学习</h4>-->
<!--                    </div>-->
<!--                    <div class="algorithmImgItem">-->
<!--                      <div class="itemImg">-->
<!--                        <img src="@/assets/image/homeCode2.png" />-->
<!--                      </div>-->
<!--                      <h4>自动建模</h4>-->
<!--                    </div>-->
<!--                    <div class="algorithmImgItem">-->
<!--                      <div class="itemImg">-->
<!--                        <img src="@/assets/image/homeCode3.png" />-->
<!--                      </div>-->
<!--                      <h4>自主命名</h4>-->
<!--                    </div>-->
<!--                    <div class="algorithmImgItem">-->
<!--                      <div class="itemImg">-->
<!--                        <img src="@/assets/image/homeCode4.png" />-->
<!--                      </div>-->
<!--                      <h4>云-端协同</h4>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </li>-->
<!--              <li>-->
<!--                <div class="advantageBg"></div>-->
<!--                <div class="advantageMain">-->
<!--                  <h2><a @click="handleClickAboutUsAdvantageFun">专业团队</a></h2>-->
<!--                  <p>PROFESSIONAL TEAM</p>-->
<!--                  <div class="algorithmImgTeam">-->
<!--                    <div class="algorithmImgTeamItem">-->
<!--                      <div class="itemImg">-->
<!--                        <img src="@/assets/image/homeTeam1.jpg" />-->
<!--                      </div>-->
<!--                      <div class="itemText">-->
<!--                        <h4>专业合作资源</h4>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="algorithmImgTeamItem">-->
<!--                      <div class="itemImg">-->
<!--                        <img src="@/assets/image/homeTeam2.jpg" />-->
<!--                      </div>-->
<!--                      <div class="itemText">-->
<!--                        <h4>顶级顾问团队</h4>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="algorithmImgTeamItem">-->
<!--                      <div class="itemImg">-->
<!--                        <img src="@/assets/image/homeTeam3.jpg" />-->
<!--                      </div>-->
<!--                      <div class="itemText">-->
<!--                        <h4>业内资深技术团队</h4>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </li>-->
<!--              <li>-->
<!--                <div class="advantageBg"></div>-->
<!--                <div class="advantageMain">-->
<!--                  <h2><a @click="handleClickAboutUsAdvantageFun">企业荣誉</a></h2>-->
<!--                  <p>ENTERPRISE HONOR</p>-->
<!--                  <div class="algorithmImgHonor">-->
<!--                    <div class="algorithmImgHonorItem">-->
<!--                      <div class="itemImg">-->
<!--                        <img src="@/assets/image/rongyu.png" />-->
<!--                      </div>-->
<!--                      <h4>省部级技术发明一等奖</h4>-->
<!--                    </div>-->
<!--                    <div class="algorithmImgHonorItem">-->
<!--                      <div class="itemImg">-->
<!--                        <img src="@/assets/image/rongyu.png" />-->
<!--                      </div>-->
<!--                      <h4>省部级科技进步二等奖</h4>-->
<!--                    </div>-->
<!--                    <div class="algorithmImgHonorItem">-->
<!--                      <div class="itemImg">-->
<!--                        <img src="@/assets/image/rongyu.png" />-->
<!--                      </div>-->
<!--                      <h4>创新创业大赛二等奖</h4>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </li>-->
            </ul>
          </div>
        </div>
        <div class="section section4">
          <div class="productCase">
            <div class="product">
              <div class="productTitle">
                <h1>
                  <router-link to="/productSeries">
                    产品中心
                  </router-link>
                </h1>
                <p>PRODUCT CENTER</p>
              </div>
              <div v-show="productCaseList.length > 1" class="butDiv">
                <a :class="aLeftClick ? 'aBtn aLeft activeBut' : 'aBtn aLeft'"  @click="handleLeftFun()">
                  <i class="el-icon-d-arrow-left"></i>
                </a>
                <a :class="aRightClick ? 'aBtn aRight activeBut' : 'aBtn aRight'" @click="handleRightFun()">
                  <i class="el-icon-d-arrow-right"></i>
                </a>
              </div>
            </div>
            <div class="scrollContainer">
              <ul ref="caseUl" :style="{position: 'absolute','top': 0,'left': 0}">
                <li v-for="(item,index) in productCaseList" :key="index">
                  <div class="caseList" v-for="list in item" :key="list.id" :title="list.title" @click="handleClickProductItemFun(list)">
                    <img width="100%" height="100%" :src="list.imageThumbnail" />
                    <div class="caseIcon">
                      <i class="el-icon-link"></i>
                    </div>
                    <div class="caseLine">
                      <span class="line1"></span>
                      <span class="line2"></span>
                      <span class="line3"></span>
                      <span class="line4"></span>
                    </div>
                  </div>
                </li>
<!--                <li>-->
<!--                  <div class="caseList">-->
<!--                    <img style="width: 100%;height: 100%" alt="about" src="@/assets/image/productDNA.jpg" />-->
<!--                    <div class="caseIcon">-->
<!--                      <i class="el-icon-link"></i>-->
<!--                    </div>-->
<!--                    <div class="caseLine">-->
<!--                      <span class="line1"></span>-->
<!--                      <span class="line2"></span>-->
<!--                      <span class="line3"></span>-->
<!--                      <span class="line4"></span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </li>-->
              </ul>
            </div>
          </div>
        </div>
        <div class="section section5">
          <h1 class="moduleTitle">解决方案</h1>
          <div class="optionContent">
            <div class="optionContents">
              <div class="item" v-for="d in page5Data" :key="d.pageId" @click="handleClickSolutionItemFun(d.pageId)">
                <div class="box">
                  <div class="font">
                    <img alt="about" style="width: 100%; height: 100%" :src="d.images[0].imageUrl" />
                  </div>
                  <div class="back">{{ d.pageName }}</div>
                </div>
              </div>
<!--              <div class="item" @click="handleClickSolutionItemFun('4-1')">-->
<!--                <div class="box">-->
<!--                  <div class="font">-->
<!--                    <img alt="about" style="width: 100%; height: 100%" src="@/assets/image/option1.jpg" />-->
<!--                  </div>-->
<!--                  <div class="back">电力数据分析</div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="item" @click="handleClickSolutionItemFun('4-2')">-->
<!--                <div class="box">-->
<!--                  <div class="font">-->
<!--                    <img alt="about" style="width: 100%; height: 100%" src="@/assets/image/option2.jpg" />-->
<!--                  </div>-->
<!--                  <div class="back">节能增效</div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="item" @click="handleClickSolutionItemFun('4-3')">-->
<!--                <div class="box">-->
<!--                  <div class="font">-->
<!--                    <img alt="about" style="width: 100%; height: 100%" src="@/assets/image/option3.jpg" />-->
<!--                  </div>-->
<!--                  <div class="back">用电安全</div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="item" @click="handleClickSolutionItemFun('4-4')">-->
<!--                <div class="box">-->
<!--                  <div class="font">-->
<!--                    <img alt="about" style="width: 100%; height: 100%" src="@/assets/image/option4.jpg" />-->
<!--                  </div>-->
<!--                  <div class="back">精细化运维</div>-->
<!--                </div>-->
<!--              </div>-->
            </div>
          </div>
        </div>
        <div class="section section6">
          <div class="cooperator">
            <div class="cooperatorTitle">
              <h1>合作单位</h1>
              <p>
                <span>服务客户</span>
                公司成立至今，合作公司遍布全国21个省、自治区、直辖市，期待与您合作。
              </p>
            </div>
            <div class="serviceXian"></div>
            <div class="serviceMain">
              <div class="serviceMainLeft">
                <div class="logoCase" v-for="img of page6Data" :key='img.imageId'>
                  <img style="width: 100%; height: 100%" :src="img.imageUrl" />
                  <div class="caseLine">
                    <span class="line1"></span>
                    <span class="line2"></span>
                    <span class="line3"></span>
                    <span class="line4"></span>
                  </div>
                </div>
              </div>
              <mapChart></mapChart>
            </div>
          </div>
        </div>
        <div class="section section7">
          <div class="productCase">
            <div class="idea">
              <h1>
                <router-link to="/talentRecruitment">
                人才理念
                </router-link>
              </h1>
              <p>人适其岗，岗适其人，人尽其才，才尽其用</p>
            </div>
            <div class="product">
              <div class="productTitle">
                <h1>
                  <router-link to="/talentRecruitment">
                    人才招聘
                  </router-link>
                </h1>
              </div>
              <div class="butDiv">
                <p>联系人：<b>{{ contacts }}</b></p>
                <p>联系方式：<b>{{ contactUs[0] }}</b></p>
              </div>
            </div>
            <div class="scrollContainer">
              <el-carousel
                arrow="never"
                :autoplay="false"
                trigger="click"
              >
                <el-carousel-item v-for="(list,index) in jobList" :key="index">
                  <div class="caseList" v-for="item in list" :key="item.id">
                    <h4>{{ item.name }}</h4>
                    <div class="range">
                      <span>招聘人数：<b>{{ item.num }}人</b></span>
                      <span>截至时间：<b>{{ item.deadline }}</b></span>
                    </div>
                    <div class="skillTags">
                      <el-tag
                        size="small"
                        effect="dark"
                        v-for="tag in item.label.split(',')"
                        :key="tag"
                      >{{ tag }}</el-tag>
                    </div>
                    <p class="titleLabel">职位描述：</p>
                    <div class="describe">
                      <p v-for="des in item.description.split('\n')"
                         :key="des">
                        {{des}}
                      </p>
                    </div>
                    <p class="titleLabel">职位要求：</p>
                    <div class="describe">
                      <p v-for="ask in item.requirement.split('\n')"
                         :key="ask">
                        {{ask}}
                      </p>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
<!--              <ul>-->
<!--                <li>-->
<!--                  <div class="caseList">-->
<!--                    <h4>高级嵌入式硬件工程师</h4>-->
<!--                    <div class="range">-->
<!--                      <span>招聘人数：<b>2人</b></span>-->
<!--                      <span>截至时间：<b>2022-12-31</b></span>-->
<!--                    </div>-->
<!--                    <div class="skillTags">-->
<!--                      <el-tag size="small" effect="dark">1-3年</el-tag>-->
<!--                      <el-tag size="small" effect="dark">大专</el-tag>-->
<!--                      <el-tag size="small" effect="dark">商务洽谈能力</el-tag>-->
<!--                    </div>-->
<!--                    <p class="titleLabel">职位描述：</p>-->
<!--                    <div class="describe">-->
<!--                      <p>1.根据项目需求进行嵌入式硬件技术方案设计；</p>-->
<!--                      <p>2.负责设计方案的关键元器件选型，例如MCU，传感器等；</p>-->
<!--                      <p>3.负责方案的电路原理图设计,生成BOM表并评估成本和批量可靠性；</p>-->
<!--                      <p>4.负责硬件研发成果进行可靠性评估；</p>-->
<!--                    </div>-->
<!--                    <p class="titleLabel">职位描述：</p>-->
<!--                    <div class="describe">-->
<!--                      <p>1.根据项目需求进行嵌入式硬件技术方案设计；</p>-->
<!--                      <p>2.负责设计方案的关键元器件选型，例如MCU，传感器等；</p>-->
<!--                      <p>3.负责方案的电路原理图设计,生成BOM表并评估成本和批量可靠性；</p>-->
<!--                      <p>4.负责硬件研发成果进行可靠性评估；</p>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </li>-->
<!--              </ul>-->
            </div>
          </div>
        </div>
        <div class="section section8">
          <h1 class="moduleTitle">新闻中心</h1>
          <div class="newsCenter">
            <div class="newsLeft">
              <div class="newsTitle">
                <div class="titleLeft">
                  <h1>公司新闻</h1>
                  <p>COMPANY NEWS</p>
                </div>
                <div class="titleRight">
                  <a @click="handleClickNewsMoreFun('6-1')">更多>></a>
                </div>
              </div>
              <div class="newsList">
                <ul>
                  <li v-for="list in cNewsList" :key="list.id" @click="handleClickNewsListFun('6-1', 16, list)">
                    <div class="time">
                      <span class="days">{{ list.time.split('-')[2] }}</span>
                      <span class="date">{{ list.time.split('-')[0] +'-'+ list.time.split('-')[1] }}</span>
                    </div>
                    <div class="conts">
                      <p> <a :title="list.title">{{ list.title }}</a> </p>
                      <p>{{ list.texts }}</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="newsRight">
              <div class="newsTitle">
                <div class="titleLeft">
                  <h1>行业新闻</h1>
                  <p>INDUSTRY NEWS</p>
                </div>
                <div class="titleRight">
                  <a @click="handleClickNewsMoreFun('6-2')">更多>></a>
                </div>
              </div>
              <div class="newsList">
                <ul>
                  <li v-for="list in pNewsList" :key="list.id" @click="handleClickNewsListFun('6-2', 17, list)">
                    <div class="time">
                      <span class="days">{{ list.time.split('-')[2] }}</span>
                      <span class="date">{{ list.time.split('-')[0] +'-'+ list.time.split('-')[1] }}</span>
                    </div>
                    <div class="conts">
                      <p> <a :title="list.title">{{ list.title }}</a> </p>
                      <p>{{ list.texts }}</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="section fp-auto-height footer">
          <footerMain></footerMain>
        </div>
      </div>
    </full-page>
  </div>
</template>

<script>
import mapChart from "@/components/echarts/mapChart"
import footerMain from '@/components/footerMain'
import Secret from "@/utils/secret";

export default {
  name: 'Home',
  data() {
    return {
      contacts: contacts,
      contactUs: contactUs,
      options: {
        menu: false, //绑定菜单，设定的相关属性与anchors的值对应后，菜单可以控制滚动，默认为false。
        anchors: [],
        // anchors: ['page1', 'page2', 'page3', 'page4', 'page5', 'page6', 'page7', 'page8', 'page9'], //anchors定义锚链接，默认为[]
        navigation: true, //是否显示导航，默认为false
        navigationPosition: 'left',//导航小圆点的位置

        //Design
        // paddingTop: "0px",
        paddingTop: "80px",
        verticalCentered: true,//定义每一页的内容是否垂直居中，默认true
        resize : true,
        sectionsColor: ['#caf9a3', '#F0F0F0', '#ac932a'],
        //Scrolling
        css3: true,//是否使用CSS3 transforms来实现滚动效果，默认为true
        scrollingSpeed: 700,//设置滚动速度，单位毫秒，默认700
        autoScrolling: true,//是否使用插件的滚动方式，默认为true,若为false则会出现浏览器自带滚动条
        fitToSection: true,//设置是否自适应整个窗口的空间，默认值：true
        scrollBar: false,//是否包含滚动条，默认为false,若为true浏览器自带滚动条出现
        easing: 'easeInOutCubic',//定义页面section滚动的动画方式，若修改此项需引入jquery.easing插件
        easingcss3: 'ease',//定义页面section滚动的过渡效果，若修改此项需引入第三方插件
        normalScrollElements: '.ideaText, .ideaTextEn',//避免自动滚动，滚动时的一些元素，例如百度地图
        scrollOverflow: true,//内容超过满屏后是否显示滚动条，true则显示滚动条，若需滚动查看内容还需要jquery.slimscroll插件的配合
        touchSensitivity: 5,//在移动设备中滑动页面的敏感性，默认为5最高100，越大越难滑动
        normalScrollElementTouchThreshold: 5,

        fixedElements: '#header',  //固定元素，默认为null,需要配置一个jquery选择器，在页面滚动时，fixElements设置的元素不滚动
        //events
        afterLoad: this.afterLoad,
        onLeave: this.onLeaveFun,
      },
      aLeftClick: false,
      aRightClick: true,
      page1Image: [],
      page2Data: {
        titles: [],
        texts: [],
        images: []
      },
      page3Data: [],
      productCaseList: [],
      page5Data: [],
      page6Data: [],
      jobList: [],
      cNewsList: [],
      pNewsList: []
    }
  },
  components: {
    mapChart,
    footerMain
  },
  computed: {
    bgImageVars() {
      return {
        '--bgImag':  `url(${this.page1Image[0]}), url(${this.page1Image[1]})`,
        '--bgImag1':  `url(${this.page1Image[0]})`,
        '--bgImag2': `url(${this.page1Image[1]})`,
      }
    },
  },
  mounted () {
    // this.$nextTick(() => {
      this.$refs.fullPageRef.init();
    // });
  },
  methods: {
    afterLoad(origin, destination, direction) {
      let _index = destination.index
      if(_index === 0) {
        this.getResourceOfPageFun({pageId: 1, locationType: 1}, _index)
      // } else if(_index === 1){
      //   this.getResourceOfPageFun({pageId: 4, locationType:2, includeSub: 1, onlyTitle: 1}, _index, 'top')
      //   this.getResourceOfPageFun({pageId: 3, locationType:2}, _index, 'bottom')
      }
    },
    onLeaveFun(origin, destination, direction) {
      let _index = destination.index
      if(_index === 1){
        this.getResourceOfPageFun({pageId: 2, locationType: 2, includeSub:1 }, _index)
      }else if(_index === 2){
        this.getResourceOfPageFun({pageId: 4, locationType: 2, includeSub: 1}, _index)
      }else if(_index === 3){
        this.getArticleListFun({pageId: 8, summaryFlag: 1}, _index)
      }else if(_index === 4){
        this.getResourceOfPageFun({pageId: 9, locationType: 2, includeSub: 1}, _index)
      }else if(_index === 5){
        this.getResourceOfPageFun({pageId: 21, locationType: 2}, _index)
      }else if(_index === 6){
        this.getJobListFun({onlyUnexpired: 1}, _index)
      }else if(_index === 7){
        this.getArticleListFun({pageId: 15, includeSub: 1, limitNum: 4}, _index)
      }
    },
    getResourceOfPageFun(params, _index, position) {
      api.getResourceOfPage(params).then((res) => {
        if(res.code === 100) {
          if(_index === 0) {
            this.page1Image = []
            let _data1 = res.data[0].images
            _data1.forEach(d => {
              this.page1Image.push(resAddressPrefix+d.imageUrl)
            })
          } else if(_index === 1){
            this.page2Data = {
              titles: [],
              texts: [],
              images: []
            }
            let _image=[], _top= []
            res.data.map(d => {
              if(d.pageId === 3) {
                this.page2Data.texts = d.pageText.split("&&");
                d.images.map(l => {
                  _image.push({
                    type: l.mimeType.split("/")[0],
                    url: resAddressPrefix+l.imageUrl
                  })
                });
              } else if(d.pageId === 5 || d.pageId === 6 || d.pageId === 7) {
                let _obj = {
                  title: d.pageName,
                  subTitle: ''
                }
                let _subTitle=[]
                d.images.map(d => {
                  _subTitle.push(d.imageTitle)
                })
                _obj.subTitle = _subTitle.join('、')
                _top.push(_obj)
              }
            })
            this.page2Data.titles = _top;
            this.page2Data.images = _image;
          } else if(_index === 2) {
            this.page3Data = []
            res.data.map(d => {
              if(d.images.length > 0) {
                d.images.map(l => {
                  l.imageUrl = resAddressPrefix+l.imageUrl
                })
                this.page3Data.push(d)
              }
            });
          }else if(_index === 4) {
            this.page5Data = []
            const _data = res.data
            _data.map(d => {
              if(d.pageId !== 9) {
                d.images[0].imageUrl = resAddressPrefix+d.images[0].imageUrl
                this.page5Data.push(d)
              }
            });
          }else if(_index === 5) {
            this.page6Data = []
            const _data = res.data[0].images
            _data.map(d => {
              d.imageUrl = resAddressPrefix+d.imageUrl
            })
            this.page6Data = _data
          }
        }else {
          this.$message.error(res.msg);
        }
      }).catch((err) => {
        if(err.msg) {
          this.$message.error(err.msg);
        }
      });
    },
    getArticleListFun(params, _index) {
      api.getArticleList(params).then((res) => {
        if(res.code === 100) {
          if(_index === 3) {
            this.productCaseList = []
            this.productCaseList = _.chunk(res.data[0].articles, 6);
          }if(_index === 7) {
            this.cNewsList = []
            this.pNewsList = []
            res.data.map(d => {
              d.articles.map(l => {
                let _texts = l.content.replace(/<[^>]+>/g, '')
                l['time'] = Secret.ConvertToDateFun(l.updateTime, 'yyyy-MM-dd')
                l['texts'] = _texts.replace(/&nbsp;/g, '');
              })
              if(d.pageId === 16) {
                this.cNewsList = d.articles
              } else if(d.pageId === 17) {
                this.pNewsList = d.articles
              }
            });
          }
        }else {
          this.$message.error(res.msg);
        }
      }).catch((err) => {
        if(err.msg) {
          this.$message.error(err.msg);
        }
      });
    },
    getJobListFun(params, _index) {
      api.getJobList(params).then((res) => {
        if(res.code === 100) {
          if(_index === 6) {
            this.jobList = []
            this.jobList = _.chunk(res.data, 3);
          }
        }else {
          this.$message.error(res.msg);
        }
      }).catch((err) => {
        if(err.msg) {
          this.$message.error(err.msg);
        }
      });
    },
    handleMove (index) {
      switch (index) {
        case 0:
          this.$refs.fullpage.api.moveTo(1, 0)
          break
        case 1:
          this.$refs.fullpage.api.moveTo(2, 1)
          break
        case 2:
          this.$refs.fullpage.api.moveTo(3, 2)
          break
      }
    },
    handleLeftFun() {
      let htmluListElement =  this.$refs.caseUl
      let _oldLeft = parseInt(htmluListElement.style.left.replace("px", ""))
      if(_oldLeft === 0) {
        console.log("左侧没有更多拉")
      } else {
        htmluListElement.style.left = (_oldLeft+1200)+'px'
        if((_oldLeft+1200)+'px' === '0px') {
          this.aLeftClick = false
        }
        this.aRightClick = true
      }
    },
    handleRightFun() {
      let htmluListElement =  this.$refs.caseUl
      let _maxIndex = htmluListElement.children.length -1
      let _oldLeft = parseInt(htmluListElement.style.left.replace("px", ""))
      let _diff = _maxIndex-(-_oldLeft/1200)
      if(_diff > 0) {
        htmluListElement.style.left = '-'+(-_oldLeft+1200)+'px'
        if('-'+(-_oldLeft+1200)+'px' === '-'+_maxIndex*1200+'px') {
          this.aRightClick = false
        }
        this.aLeftClick = true
      } else {
        console.log("右侧没有更多拉")
      }
    },
    handleClickProductItemFun(item) {
      this.$store.commit('SET_ARTICLES_ID', item.id)
      this.$store.commit('SET_SECONDARY_ROUTING_INDEX', "3-2")
      this.$router.push({
        path: '/productAndSolutionDetails',
        query: {data: btoa(encodeURIComponent(
            JSON.stringify({type: 'product', pageId: 8})
          )),
        }
      })
    },
    handleClickAboutUsAdvantageFun() {
      this.$store.commit('SET_CURRENT_ROUTE', '/companyProfile')
      this.$store.commit('SET_SECONDARY_ROUTING_INDEX', '2-2')
      this.$router.push({path: '/companyProfile'})
    },
    handleClickSolutionItemFun(id) {
      this.$store.commit('SET_CURRENT_ROUTE', '/solution')
      this.$store.commit('SET_SECONDARY_ROUTING_INDEX', this.loadListDataFun(id))
      this.$router.push({path: '/solution'})
    },
    loadListDataFun(key) {
      switch (key) {
        case 10:
          return '4-1';
        case 11:
          return '4-2';
        case 12:
          return '4-3';
        case 13:
          return '4-4';
      }
    },
    handleClickNewsMoreFun(type) {
      this.$store.commit('SET_CURRENT_ROUTE', '/pressCenter')
      this.$store.commit('SET_SECONDARY_ROUTING_INDEX', type)
      this.$router.push({path: '/pressCenter'})
    },
    handleClickNewsListFun(type, pageId, list) {
      this.$store.commit('SET_ARTICLES_ID', list.id)
      this.$store.commit('SET_SECONDARY_ROUTING_INDEX', type)
      this.$router.push({
        path: '/pressCenterDetails',
        query: {data: btoa(encodeURIComponent(JSON.stringify({type: 'news', pageId: pageId}))),
        }
      })
    },
  },
  beforeDestroy() {
    window.location.reload();
  }
}
</script>

<style scoped lang="scss">
.home{
  a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
  }
  .section {
    display: table;
    vertical-align: middle;
    ::v-deep .fp-tableCell {
      width: 100%;
      display: table-cell;
      vertical-align: middle;
    }
  }
  .section1 {
    //background-image: url("~images/banner1-3.jpg"),url("~images/banner1-4.jpg");
    //background-image: url(var(--bgImag1)), url(var(--bgImag2));
    background-image: var(--bgImag);
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    animation: backgroundAnimation 4s ease-in-out infinite;
    ::v-deep .fp-tableCell {
      //width: 100%;
      color: #FFFFFF;
      //display: table-cell;
      //vertical-align: middle;
      .ideaText {
        margin: 0 auto;
        font-size: 5em;
        font-weight: 600;
        font-family: '微软雅黑';
        span {
          position: relative;
          color: rgba(255,255,255,1);
          &:after {
            position: absolute;
            top: 0;
            left: 0;
            content: attr(data-text);
            color: #fff;
            opacity: 0;
            transform: scale(1.5);
            animation: loading 3s infinite;
          }
          &:nth-child(4) {
            margin-right: 20px;
          }
          &:nth-child(5) {
            margin-left: 20px;
          }

          &:nth-child(2) {
            &:after {
              animation-delay: 0.1s;
            }
          }
          &:nth-child(3)::after {
            animation-delay: 0.2s;
          }
          &:nth-child(4)::after {
            animation-delay: 0.3s;
          }
          &:nth-child(5)::after {
            animation-delay: 0.4s;
          }
          &:nth-child(6)::after {
            animation-delay: 0.5s;
          }
          &:nth-child(7)::after {
            animation-delay: 0.6s;
          }
        }
      }
      .ideaTextEn {
        width: 670px;
        font-size: 1.4em;
        margin: 0 auto;
        text-align: justify;
        text-align-last: justify;
      }
    }
  }
  @keyframes loading {
    0%, 75%, 100% {
      transform: scale(1.5);
      opacity: 0;
    }
    25%,
    50% {
      transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes backgroundAnimation {
    0% {
      background-image: var(--bgImag1);
    }
    50% {
      background-image: var(--bgImag2);
    }
    100% {
      background-image: var(--bgImag1);
    }
  }
  .section2 {
    background: url("~images/bg3.jpg") no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    ::v-deep .fp-tableCell {
      position: relative;
      .aboutTitles {
        width: 1200px;
        margin: 0 auto;
        ul {
          width: 100%;
          margin: 0 0 4em 0;
          padding: 0;
          list-style: none;
          display: flex;
          li {
            flex: 1;
            float: left;
            margin: auto;
            .logoDiv {
              width: 80px;
              height: 80px;
              display: inline-block;
              vertical-align: middle;
              img {
                width: 100%;
                height: 100%;
              }
            }
            h2 {
              font-size: 2em;
              padding-left: 20px;
              display: inline-block;
              vertical-align: middle;
            }
            .icons {
              height: 100%;
              .el-icon-d-arrow-right {
                color: #004AAE;
                font-size: 28px;
              }
            }
            .liner {
              .p1 {
                text-align: left;
                color: #004AAE;
                font-size: 18px;
                line-height: 40px;
                border-bottom: 1px solid #595757;
                margin: 0 0 5px 0;
              }
              .p2 {
                margin: 0;
                text-align: left;
                font-size: 12px;
                line-height: 22px;
              }
            }
            &:nth-child(1) {
              flex: 100px;
            }
          }
        }
      }
      .aboutContent {
        width: 1200px;
        overflow: hidden;
        margin: auto;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        .contents{
          text-align: left;
          display: inline-block;
          padding-right: 20px;
          width: calc(100% - 500px);
          vertical-align: middle;
          -webkit-transition: all 1s;
          -moz-transition: all 1s;
          -o-transition: all 1s;
          transition: all 1s;
          -webkit-transform: translate3d(-600px, 0, 0);
          -moz-transform: translate3d(-600px, 0, 0);
          transform: translate3d(-600px, 0, 0);
          -ms-transform: translate3d(-600px, 0, 0);
          p {
            text-indent: 2em;
            line-height: 1.8;
          }
          a {
            position: absolute;
            bottom: 2px;
            left: 2em;
            width: 118px;
            height: 38px;
            line-height: 38px;
            font-size: 14px;
            color: #004AAE;
            border: 1px solid #0C5DB1;
            border-radius: 3px;
            text-align: center;
            cursor: pointer;
            transition: color 0.4s ease 0s;
            white-space: nowrap;
            &:hover {
              color: #FFFFFF;
              background: #0C5DB1;
            }
          }
        }
        .aboutImg {
          width: 480px;
          height: 380px;
          display: inline-block;
          vertical-align: top;
          -webkit-transition: all 1s;
          -moz-transition: all 1s;
          -o-transition: all 1s;
          transition: all 1s;
          -webkit-transform: translate3d(600px, 0, 0);
          -moz-transform: translate3d(600px, 0, 0);
          transform: translate3d(600px, 0, 0);
          -ms-transform: translate3d(600px, 0, 0);
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .section3 {
    background: url("~images/bg2.jpg") no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    ::v-deep .fp-tableCell {
      .fp-scroller {
        height: 100%;
      }
      height: 100%;
      color: #FFFFFF;
      position: relative;
      overflow: hidden;
      .advantage {
        margin: 0 auto;
        width: 1200px;
        height: 100%;
        ul {
          display: flex;
          width: 100%;
          height: 100%;
          margin: 0;
          padding: 0;
          li {
            flex: 1;
            float: left;
            list-style: none;
            display: table;
            padding: 0 30px;
            vertical-align: middle;
            position: relative;
            -webkit-transition: all 1s;
            -moz-transition: all 1s;
            -o-transition: all 1s;
            transition: all 1s;
            .advantageMain {
              padding-top: 120px;
              display: table-cell;
              vertical-align: middle;
              transition: all 0.5s;
              position: relative;
              //.advantageIcon{
              //  overflow: hidden;
              //  margin: 0 auto;
              //  width: 110px;
              //  height: 110px;
              //  cursor: pointer;
              //  background: url("../assets/image/fwxm_img1.png") no-repeat;
              //}
              h2{
                margin-bottom: 0;
                width: 100%;
                position: absolute;
                top: 180px;
              }
              p {
                margin-top: 6px;
                width: 100%;
                position: absolute;
                top: 230px;
              }
              //.advantageDes{
              //  margin-top: 20px;
              //  font-size: 12px;
              //  line-height: 26px;
              //}
              //a {
              //  opacity: 0;
              //  font-size: 16px;
              //  width: 143px;
              //  height: 33px;
              //  line-height: 33px;
              //  margin-top: 35px;
              //  display: inline-block;
              //  border-radius: 5px;
              //  border: 1px solid #fff;
              //  cursor: pointer;
              //  transition: background-color 0.3s, color 0.3s;
              //  &:hover {
              //    background: #FFFFFF;
              //    animation: anim-moema-1 0.3s forwards;
              //    transition: background-color 0.1s 0.3s, color 0.1s 0.3s;
              //  }
              //}
              .algorithmImg,
              .algorithmImgTeam,
              .algorithmImgHonor {
                margin-top: 8em;
                display: inline-block;
                vertical-align: middle;
              }
              .algorithmImg {
                .algorithmImgItem {
                  display: inline-block;
                  margin-bottom: 2em;
                  .itemImg {
                    width: 140px;
                    height: 140px;
                    margin: auto;
                    border-radius: 55px;
                    overflow: hidden;
                    cursor: pointer;
                    img {
                      width: 100%;
                      height: 100%;
                    }
                  }
                  h4 {
                    margin: 0.6em;
                  }
                  &:nth-child(odd)  {
                    padding-right: 20px;
                  }
                }
              }
              .algorithmImgTeam {
                margin-top: 4em;
                text-align: justify;

                .algorithmImgTeamItem {
                  display: inline-block;
                  margin-bottom: 2em;
                  .itemImg {
                    width: 160px;
                    height: 110px;
                    overflow: hidden;
                    img {
                      width: 100%;
                      height: 100%;
                    }
                  }
                  .itemText {
                    padding: 10px;
                    background-color: #ffffff;
                    text-align: center;
                    position: relative;
                    background: -webkit-linear-gradient(135deg, transparent 10px, #fff 0);
                    background: -moz- oldlinear-gradient(135deg, transparent 10px, #fff 0);
                    background: -o-linear-gradient(135deg, transparent 10px, #fff 0);
                    background: linear-gradient(-45deg, transparent 10px, #fff 0);
                    h4 {
                      margin: 0;
                      color: #58596d;
                      white-space: nowrap;
                      -o-text-overflow: ellipsis;
                      text-overflow: ellipsis;
                      overflow: hidden;
                    }
                  }
                  &:nth-child(odd)  {
                    padding-right: 20px;
                  }
                }
              }
              .algorithmImgHonor {
                text-align: justify;

                .algorithmImgHonorItem {
                  display: inline-block;
                  margin-bottom: 1em;
                  .itemImg {
                    width: 140px;
                    height: 140px;
                    overflow: hidden;
                    img {
                      width: 100%;
                      height: 100%;
                    }
                  }
                  h4 {
                    margin: 20px 0;
                  }
                  &:nth-child(odd)  {
                    padding-right: 20px;
                  }
                }
              }
            }
            .advantageBg {
              position: absolute;
              right: 0;
              bottom: 0;
              left: 0;
              height: 0;
              -webkit-transition: all .5s;
              -moz-transition: all .5s;
              -o-transition: all .5s;
              transition: all .5s;
            }
            &:hover {
              .advantageBg {
                height: 100%;
              }
              .advantageMain {
                a {
                  opacity: 1;
                }
              }
            }
            &:nth-child(1) {
              .advantageBg {
                background: #1599a5;
              }
              //.advantageMain {
              //  a {
              //    &:hover {
              //      color: #1599a5;
              //    }
              //  }
              //}
            }
            &:nth-child(2) {
              .advantageBg {
                background: #5e904d;
              }
              //.advantageMain {
              //  a {
              //    &:hover {
              //      color: #5e904d;
              //    }
              //  }
              //}
            }
            &:nth-child(3) {
              .advantageBg {
                background: #5d4378;
              }
              //.advantageMain {
              //  a {
              //    &:hover {
              //      color: #5d4378;
              //    }
              //  }
              //}
            }
            &:nth-child(odd) {
              -webkit-transform: translate3d(0, -1080px, 0);
              -moz-transform: translate3d(0, -1080px, 0);
              transform: translate3d(0, -1080px, 0);
              -ms-transform: translate3d(0, -1080px, 0);
            }
            &:nth-child(even) {
              -webkit-transform: translate3d(0, 2160px, 0);
              -moz-transform: translate3d(0, 2160px, 0);
              transform: translate3d(0, 2160px, 0);
              -ms-transform: translate3d(0, 2160px, 0);
            }
          }
        }
      }
    }
  }
  .section4 {
    background: url("~images/bg4.jpg") no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    ::v-deep .fp-tableCell {
      .productCase {
        color: #FFFFFF;
        width: 1200px;
        margin: auto;
        overflow: hidden;
        text-align: left;
        .product {
          .productTitle {
            width: calc(100% - 100px);
            display: inline-block;
            -webkit-transition: all 1s;
            -moz-transition: all 1s;
            -o-transition: all 1s;
            transition: all 1s;
            -webkit-transform: translate3d(-100px, 0, 0);
            -moz-transform: translate3d(-100px, 0, 0);
            transform: translate3d(-100px, 0, 0);
            -ms-transform: translate3d(-100px, 0, 0);
            h1 {
              margin: 0;

            }
            p {
              margin-top: 6px;
              margin-bottom: 0;
              font-size: 18px;
            }
          }
          .butDiv {
            width: 100px;
            height: 30px;
            display: inline-block;
            -webkit-transition: all 1s;
            -moz-transition: all 1s;
            -o-transition: all 1s;
            transition: all 1s;
            -webkit-transform: translate3d(100px, 0, 0);
            -moz-transform: translate3d(100px, 0, 0);
            transform: translate3d(100px, 0, 0);
            -ms-transform: translate3d(100px, 0, 0);
            .aBtn {
              color: #FFFFFF;
              width: 45px;
              height: 30px;
              line-height: 30px;
              display: inline-block;
              text-align: center;
              cursor: default;
              background: #2A2A2A;
            }
            .aRight {
              float: right;
            }
            .activeBut {
              cursor: pointer;
              background: #0DDFBA;
            }
          }
        }
        .scrollContainer {
          width: 1200px;
          height: 420px;
          overflow: hidden;
          margin: 38px auto 0 auto;
          position: relative;
          ul {
            width: 1000%;
            margin: 0;
            padding: 0;
            transition: left 0.3s;
            li {
              list-style: none;
              float: left;
              overflow: hidden;
              width: 1200px;
              padding: 0;
              .caseList {
                position: relative;
                float: left;
                overflow: hidden;
                margin: 0 20px 20px 0;
                width: 386px;
                height: 200px;
                cursor: pointer;
                -webkit-transition: all 1.5s;
                -moz-transition: all 1.5s;
                -o-transition: all 1.5s;
                transition: all 1.5s;
                -webkit-transform: translate3d(0, 120px, 0);
                -moz-transform: translate3d(0, 120px, 0);
                transform: translate3d(0, 120px, 0);
                -ms-transform: translate3d(0, 120px, 0);
                &:nth-child(4),&:nth-child(5),&:nth-child(6) {
                  margin-bottom: 0;
                }
                &:nth-child(3),&:nth-child(6) {
                  margin-right: 0;
                }
                &:hover {
                  .caseIcon {
                    opacity: 1;
                  }
                  .caseLine {
                    span {
                      &:nth-child(1),
                      &:nth-child(3) {
                        width: 342px;
                      }
                      &:nth-child(2),
                      &:nth-child(4) {
                        height: 156px;
                      }
                    }
                  }
                }
                .caseIcon {
                  opacity: 0;
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 386px;
                  height: 200px;
                  text-align: center;
                  transition: all .3s;
                  background: rgba(0,0,0,0.5);
                  i {
                    color: #0DDFBA;
                    font-size: 60px;
                    margin-top: 70px;
                  }
                }
                .caseLine {
                  position: absolute;
                  top: 22px;
                  left: 22px;
                  width: 342px;
                  height: 156px;
                  span {
                    position: absolute;
                    background: #0ddfba;
                    -webkit-transition: all .3s cubic-bezier(.25, .46, .45, .94);
                    -moz-transition: all .3s cubic-bezier(.25, .46, .45, .94);
                    -o-transition: all .3s cubic-bezier(.25, .46, .45, .94);
                    transition: all .3s cubic-bezier(.25, .46, .45, .94);
                    &:nth-child(1) {
                      top: 0;
                      left: 0;
                      width: 0;
                      height: 2px;
                    }
                    &:nth-child(2) {
                      top: 0;
                      right: 0;
                      width: 2px;
                      height: 0;
                    }
                    &:nth-child(3) {
                      right: 0;
                      bottom: 0;
                      width: 0;
                      height: 2px;
                    }
                    &:nth-child(4) {
                      bottom: 0;
                      left: 0;
                      width: 2px;
                      height: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .section5 {
    background: url("~images/bg5.jpg") no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    ::v-deep .fp-tableCell {
      //color: #FFFFFF;
      position: relative;
      .optionContent{
        width: 1200px;
        overflow: hidden;
        margin: auto;
        position: relative;
        bottom: -60px;
        .optionContents {
          //width: 600px;
          display: inline-block;
          .item{
            width:600px;
            height:240px;
            cursor: pointer;
            display: inline-block;
            perspective:500px;
            &:hover .box{
              transform: rotateY(180deg);
            }
            .box{
              background: #abb9c5;
              width:100%;
              height:100%;
              transition:all 1s;
              transform-style: preserve-3d;
              position: relative;
              .font,.back{
                position: absolute;
                top:0;
                left:0;
                width:100%;
                height:100%;
                text-align: center;
                line-height: 200px;

                backface-visibility: hidden;
              }
              .back{
                font-size: 30px;
                transform: rotateY(180deg);
              }
            }
          }
        }
        .optionMap {
          width: 600px;
          height: 488px;
          display: inline-block;
        }
      }
    }
  }
  .section6 {
    background: url("~images/bg7.jpg") no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    ::v-deep .fp-tableCell {
      .cooperator {
        color: #FFFFFF;
        width: 1200px;
        margin: auto;
        overflow: hidden;
        .cooperatorTitle {
          display: inline-block;
          -webkit-transition: all 1s;
          -moz-transition: all 1s;
          -o-transition: all 1s;
          transition: all 1s;
          -webkit-transform: translate3d(0, -100px, 0);
          -moz-transform: translate3d(0, -100px, 0);
          transform: translate3d(0, -100px, 0);
          -ms-transform: translate3d(0, -100px, 0);
          h1 {
            margin: 0;
          }
          p {
            margin-top: 6px;
            margin-bottom: 0;
            font-size: 16px;
            span {
              font-size: 18px;
              margin-right: 10px;
            }
          }
        }
        .serviceXian {
          width: 0;
          margin-top: 28px;
          height: 3px;
          background: #0ddfba;
          -webkit-transition: all 1s ease 0s;
          -moz-transition: all 1s ease 0s;
          transition: all 1s ease 0s;
          -ms-transition: all 1s ease 0s;
        }
        .serviceMain {
          margin: 0 auto;
          width: 1160px;
          background: rgba(255,255,255,0.08);
          overflow: hidden;
          position: relative;
          -webkit-transition: all 1s;
          -moz-transition: all 1s;
          -o-transition: all 1s;
          transition: all 1s;
          -webkit-transform: translate3d(0, 100px, 0);
          -moz-transform: translate3d(0, 100px, 0);
          transform: translate3d(0, 100px, 0);
          -ms-transform: translate3d(0, 100px, 0);
          .serviceMainLeft {
            width: 550px;
            height: 480px;
            float: left;
            padding: 22px;
            text-align: left;
            overflow-x: auto;
            display: inline-block;
            .logoCase {
              width: 170px;
              height: 100px;
              display: inline-block;
              position: relative;
              margin-right: 7px;
              margin-bottom: 20px;
              cursor: pointer;
              &:nth-child(3n) {
                margin-right: 0px;
              }
              &:hover {
                .caseIcon {
                  opacity: 1;
                }
                .caseLine {
                  span {
                    &:nth-child(1),
                    &:nth-child(3) {
                      width: 170px;
                    }
                    &:nth-child(2),
                    &:nth-child(4) {
                      height: 100px;
                    }
                  }
                }
              }
              .caseLine {
                //position: absolute;
                position: initial;
                width: 170px;
                height: 100px;
                span {
                  position: absolute;
                  background: #0ddfba;
                  -webkit-transition: all .3s cubic-bezier(.25, .46, .45, .94);
                  -moz-transition: all .3s cubic-bezier(.25, .46, .45, .94);
                  -o-transition: all .3s cubic-bezier(.25, .46, .45, .94);
                  transition: all .3s cubic-bezier(.25, .46, .45, .94);
                  &:nth-child(1) {
                    top: 0;
                    left: 0;
                    width: 0;
                    height: 2px;
                  }
                  &:nth-child(2) {
                    top: 0;
                    right: 0;
                    width: 2px;
                    height: 0;
                  }
                  &:nth-child(3) {
                    right: 0;
                    bottom: 0;
                    width: 0;
                    height: 2px;
                  }
                  &:nth-child(4) {
                    bottom: 0;
                    left: 0;
                    width: 2px;
                    height: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .section7 {
    background: url("~images/bg6.jpg") no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    ::v-deep .fp-tableCell {
      .productCase {
        //color: #FFFFFF;
        width: 1200px;
        margin: auto;
        overflow: hidden;
        text-align: left;
        .idea {
          text-align: center;
          padding-bottom: 2em;
          -webkit-transition: all 1s;
          -moz-transition: all 1s;
          -o-transition: all 1s;
          transition: all 1s;
          -webkit-transform: translate3d(0, -100px, 0);
          -moz-transform: translate3d(0, -100px, 0);
          transform: translate3d(0, -100px, 0);
          -ms-transform: translate3d(0, -100px, 0);
          h1 {
            font-size: 1.8em;
          }
          p {
            font-size: 1.4em;
          }
        }

        .product {
          .productTitle {
            width: calc(100% - 174px);
            display: inline-block;
            -webkit-transition: all 1s;
            -moz-transition: all 1s;
            -o-transition: all 1s;
            transition: all 1s;
            -webkit-transform: translate3d(-100px, 0, 0);
            -moz-transform: translate3d(-100px, 0, 0);
            transform: translate3d(-100px, 0, 0);
            -ms-transform: translate3d(-100px, 0, 0);
            h1 {
              margin: 0;
              font-size: 1.8em;
              text-align: left;
            }

          }
          .butDiv {
            width: 174px;
            display: inline-block;
            -webkit-transition: all 1s;
            -moz-transition: all 1s;
            -o-transition: all 1s;
            transition: all 1s;
            -webkit-transform: translate3d(100px, 0, 0);
            -moz-transform: translate3d(100px, 0, 0);
            transform: translate3d(100px, 0, 0);
            -ms-transform: translate3d(100px, 0, 0);
            p {
              margin: 0;
              font-size: 14px;
            }
          }
        }
        .scrollContainer {
          width: 1200px;
          //height: 420px;
          //overflow: hidden;
          margin: 10px auto 0 auto;
          position: relative;
          .el-carousel {
            //height: auto !important;
            height: 420px;
            overflow: hidden;
            .el-carousel__container {
              height: 390px !important;
              overflow-x: hidden;
              .el-carousel__item {
                height: auto !important;
                .caseList {
                  position: relative;
                  float: left;
                  //overflow: hidden;
                  margin: 0 30px 0 0;
                  width: 360px;
                  //height: 440px;
                  cursor: pointer;
                  -webkit-transition: all 1s;
                  -moz-transition: all 1s;
                  -o-transition: all 1s;
                  transition: all 1s;
                  //-webkit-transform: translate3d(0, 100px, 0);
                  //transform: translate3d(0, 100px, 0);
                  &:nth-child(3) {
                    margin-right: 0;
                  }
                  .range {
                    font-size: 14px;
                    span{
                      &:nth-child(2) {
                        float: right;
                      }
                    }
                  }
                  .skillTags {
                    margin: 10px 0;
                    .el-tag--small {
                      margin-right: 4px;
                    }
                  }
                  .titleLabel {
                    font-size: 14px;
                    margin: 20px 0 6px 0;
                  }
                  .describe {
                    p {
                      margin-top: 0;
                      margin-bottom: 4px;
                      line-height: 1.6;
                    }
                  }

                }
              }
            }
            .el-carousel__indicator--horizontal {
              button {
                height: 4px;
                background-color: #3ebaff;
              }
            }
          }
        }
      }
    }
  }
  .section8 {
    background: url("~images/bg8.jpg") no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    ::v-deep .fp-tableCell {
      position: relative;
      .newsCenter {
        width: 1200px;
        overflow: hidden;
        margin: auto;
        height: 486px;
        padding-top: 4em;
        .newsLeft,
        .newsRight {
          width: 570px;
          vertical-align: top;
          display: inline-block;
          .newsTitle {
            .titleLeft {
              text-align: left;
              width: calc(100% - 42px);
              display: inline-block;
              h1 {
                font-size: 24px;
                margin: 0;
              }
              p {
                margin-top: 6px;
                margin-bottom: 0;
                font-size: 14px;
              }
            }
            .titleRight {
              width: 42px;
              height: 16px;
              line-height: 16px;
              cursor: pointer;
              display: inline-block;
              a {
                font-size: 12px;
              }
            }
          }
          .newsList {
            overflow: hidden;
            ul {
              margin: 0;
              padding: 10px 0;
              list-style: none;
              li {
                overflow: hidden;
                margin: 10px 0;
                padding: 10px 0;
                list-style: none;
                background: rgba(255,255,255,0.6);
                border-bottom: 1px dotted #979797;
                .time {
                  color: #fff;
                  width: 60px;
                  height: 60px;
                  float: left;
                  text-align: center;
                  margin-left: 14px;
                  background: #4599D7;
                  .days {
                    margin-top: 10px;
                    font-size: 24px;
                    display: inline-block;
                    font-weight: bold;
                    text-decoration: none;
                  }
                  .date {
                    font-size: 12px;
                    display: block;
                    height: 18px;
                    line-height: 18px;
                  }
                }
                .conts {
                  margin-left: 88px;
                  p {
                    font-size: 14px;
                    line-height: 18px;
                    text-align: left;
                    &:nth-child(1) {
                      margin: 0;
                    }
                    &:nth-child(2) {
                      font-size: 12px;
                      height: 36px;
                      margin-bottom: 0;
                      word-break: break-all;
                      text-overflow: ellipsis;
                      display: -webkit-box;
                      -webkit-box-orient: vertical;
                      -webkit-line-clamp: 2;
                      overflow: hidden;
                    }
                    a{
                      display: inline-block;
                      font-size: 16px;
                      padding-top: 2px;
                      color: #434343;
                      font-weight: bold;
                      text-decoration: none;
                      cursor: pointer;
                    }
                  }
                }
              }
            }
          }
        }
        .newsLeft {
          padding-right: 30px;
          -webkit-transition: all 1s;
          -moz-transition: all 1s;
          -o-transition: all 1s;
          transition: all 1s;
          -webkit-transform: translate3d(-100px, 0, 0);
          -moz-transform: translate3d(-100px, 0, 0);
          transform: translate3d(-100px, 0, 0);
          -ms-transform: translate3d(-100px, 0, 0);
          ::v-deep .time {
            background: #005EA6;
          }
        }
        .newsRight {
          padding-left: 30px;
          -webkit-transition: all 1s;
          -moz-transition: all 1s;
          -o-transition: all 1s;
          transition: all 1s;
          -webkit-transform: translate3d(100px, 0, 0);
          -moz-transform: translate3d(100px, 0, 0);
          transform: translate3d(100px, 0, 0);
          -ms-transform: translate3d(100px, 0, 0);
        }
      }
    }
  }
  .footer {
    height: auto !important;
    padding-top: 0px !important;
    //background-color: rgb(27, 27, 27);
    ::v-deep .fp-tableCell {
      //color: #999999;
      //width: 100%;
      height: 100% !important;
      //margin: auto;
      //display: table-cell;
      //vertical-align: middle;
    }
  }
  .moduleTitle {
    position: absolute;
    top: 0;
    left: calc((100% - 160px) / 2);
    padding-bottom: 6px;
    -webkit-transition: all 1s ease 0s;
    -moz-transition: all 1s ease 0s;
    transition: all 1s ease 0s;
    -ms-transition: all 1s ease 0s;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      bottom: -2px;
      width: 100%;
      height: 4px;
      background: linear-gradient(to right,  #004AAE, #F0F0F0, #004AAE);
    }
  }

  @-webkit-keyframes anim-moema-1 {
    60% {
      -webkit-transform: scale3d(0.8, 0.8, 1);
      transform: scale3d(0.8, 0.8, 1);
    }
    85% {
      -webkit-transform: scale3d(1.1, 1.1, 1);
      transform: scale3d(1.1, 1.1, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
  @keyframes anim-moema-1 {
    60% {
      -webkit-transform: scale3d(0.8, 0.8, 1);
      transform: scale3d(0.8, 0.8, 1);
    }
    85% {
      -webkit-transform: scale3d(1.1, 1.1, 1);
      transform: scale3d(1.1, 1.1, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
  @-webkit-keyframes moving {
    from {
      opacity: 0;
      -webkit-transform: translateY(50%);
    }
    to {
      opacity: 1;
      -webkit-transform: translateY(0%);
    }
  }
  @keyframes moving {
    from {
      opacity: 0;
      transform: translateY(50%);
    }
    to {
      opacity: 1;
      transform: translateY(0%);
    }
  }

  .active {
    ::v-deep .fp-tableCell{
      .advantage{ //优势
        ul {
          li {
            &:nth-child(odd) {
              -webkit-transform: translate3d(0, 0, 0);
              -moz-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
              -ms-transform: translate3d(0, 0, 0);
            }
            &:nth-child(even) {
              -webkit-transform: translate3d(0, 0, 0);
              -moz-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
              -ms-transform: translate3d(0, 0, 0);
            }
          }
        }
      }
      .aboutContent{ //关于
        .contents, .aboutImg {
          -webkit-transform: translate3d(0, 0, 0);
          -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
          -ms-transform: translate3d(0, 0, 0);
        }
      }
      .productCase { //产品、招聘
        .idea {
          -webkit-transform: translate3d(0, 0, 0);
          -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
          -ms-transform: translate3d(0, 0, 0);
        }
        .product {
          .productTitle, .butDiv {
            -webkit-transform: translate3d(0, 0, 0);
            -moz-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
            -ms-transform: translate3d(0, 0, 0);
          }
        }
        .scrollContainer {
          ul {
            li {
              .caseList {
                -webkit-transform: translate3d(0, 0, 0);
                -moz-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
                -ms-transform: translate3d(0, 0, 0);
              }
            }
          }
        }
      }
      .cooperator { //合作单位
        .cooperatorTitle,
        .serviceMain {
          -webkit-transform: translate3d(0, 0, 0);
          -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
          -ms-transform: translate3d(0, 0, 0);
        }
        .serviceXian {
          width: 100%;
        }
      }
      .newsCenter{ //新闻
        .newsLeft,
        .newsRight {
          -webkit-transform: translate3d(0, 0, 0);
          -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
          -ms-transform: translate3d(0, 0, 0);
        }
      }
      .fullContents{
        .fullContent{
          .fullContentLeft,
          .fullContentRight {
            -webkit-animation: moving 1s linear;
            animation: moving 1s linear;
          }
        }
      }
      .moduleTitle{
        top: 40px;
      }
    }
  }
}
</style>

