<template>
  <div id="main" class="mapChart"></div>
</template>

<script>
// echarts5.0以上版本使用中国地图
import * as echarts from "echarts";
import dataJson from "./china.json"
export default {
  data() {
    return {
      selectName: '',
      myChart: null,
      options: null,
    }
  },
  watch: {
  },
  mounted() {
    this.myChartsFun()
  },
  methods: {
    myChartsFun() {
      this.myChart = echarts.init(document.getElementById("main"));
      let uploadDataUrl = dataJson;
      // 注册地图
      echarts.registerMap("china", uploadDataUrl);
      this.option = {
        backgroundColor: 'rgba(0,0,0,0)',
        tooltip : {
          trigger: 'item',
        },
        geo: {
          map: 'china',
          label: {
            emphasis: {
              show: false
            }
          },
          roam: false,
          zoom: 1.4,
          top: 150,
          itemStyle:{
            normal:{
              color: "#FFFF00",
              label:{show:false},
              // areaColor: "#E0E0E0", //默认板块颜色
              areaColor: "#031525", //默认板块颜色
              borderColor:'#2D91BF',
              borderWidth:1,
              borderType:'solid',
            },
            emphasis:{
              label:{show:false},
              areaColor:'#031525'
            }
          },
        },
        //配置属性
        series: [
          {
          name: '智源科技',
          type: 'lines',
          coordinateSystem: 'geo',
          polyline: true,
          silent: true,
          zlevel: 1,
          effect: {
            show: true,
            period: 6,
            trailLength: 0.7,
            color: '#fff',
            symbolSize: 3
          },
          lineStyle: {
            normal: {
              color: "#FFFF00",
              width: 0,
              curveness: 0.2
            }
          },
          data: [
            [{coord:[117.190182,39.125596]},{coord:[117.190182,39.125596]}],
            [{coord:[117.190182,39.125596]},{coord:[116.405285,39.904989]}],
            [{coord:[117.190182,39.125596]},{coord:[114.502461,38.045474]}],
            [{coord:[117.190182,39.125596]},{coord:[108.320004,22.82402]}],
            [{coord:[117.190182,39.125596]},{coord:[112.549248,37.857014]}],
            [{coord:[117.190182,39.125596]},{coord:[117.000923,36.675807]}],
            [{coord:[117.190182,39.125596]},{coord:[113.665412,34.757975]}],
            [{coord:[117.190182,39.125596]},{coord:[118.767413,32.041544]}],
            [{coord:[117.190182,39.125596]},{coord:[121.472644,31.231706]}],
            [{coord:[117.190182,39.125596]},{coord:[120.153576,30.287459]}],
            [{coord:[117.190182,39.125596]},{coord:[106.278179,38.46637]}],
            [{coord:[117.190182,39.125596]},{coord:[104.065735,30.659462]}],
            [{coord:[117.190182,39.125596]},{coord:[112.982279,28.19409]}],
            [{coord:[117.190182,39.125596]},{coord:[106.713478,26.578343]}],
            [{coord:[117.190182,39.125596]},{coord:[119.306239,26.075302]}],
            [{coord:[117.190182,39.125596]},{coord:[108.320004,22.82402]}],
            [{coord:[117.190182,39.125596]},{coord:[113.280637,23.125178]}],
            [{coord:[117.190182,39.125596]},{coord:[102.712251,25.040609]}],
            [{coord:[117.190182,39.125596]},{coord:[126.642464,45.756967]}],
            [{coord:[117.190182,39.125596]},{coord:[125.3245,43.886841]}],
            [{coord:[117.190182,39.125596]},{coord:[111.670801,40.818311]}],
            [{coord:[117.190182,39.125596]},{coord:[87.617733,43.792818]}],
          ]
        },
          {
            name: '智源科技',
            type: 'lines',
            coordinateSystem: 'geo',
            polyline: true,
            silent: true,
            zlevel: 2,
            effect: {
              show: true,
              period: 6,
              trailLength: 0,
              symbolSize: 4
            },
            lineStyle: {
              normal: {
                color: "#FFFF00",
                width: 1,
                opacity: 0.4,
                curveness: 0.2
              }
            },
            data: [
              [{coord:[117.190182,39.125596]},{coord:[117.190182,39.125596]}],
              [{coord:[117.190182,39.125596]},{coord:[116.405285,39.904989]}],
              [{coord:[117.190182,39.125596]},{coord:[114.502461,38.045474]}],
              [{coord:[117.190182,39.125596]},{coord:[108.320004,22.82402]}],
              [{coord:[117.190182,39.125596]},{coord:[112.549248,37.857014]}],
              [{coord:[117.190182,39.125596]},{coord:[117.000923,36.675807]}],
              [{coord:[117.190182,39.125596]},{coord:[113.665412,34.757975]}],
              [{coord:[117.190182,39.125596]},{coord:[118.767413,32.041544]}],
              [{coord:[117.190182,39.125596]},{coord:[121.472644,31.231706]}],
              [{coord:[117.190182,39.125596]},{coord:[120.153576,30.287459]}],
              [{coord:[117.190182,39.125596]},{coord:[106.278179,38.46637]}],
              [{coord:[117.190182,39.125596]},{coord:[104.065735,30.659462]}],
              [{coord:[117.190182,39.125596]},{coord:[112.982279,28.19409]}],
              [{coord:[117.190182,39.125596]},{coord:[106.713478,26.578343]}],
              [{coord:[117.190182,39.125596]},{coord:[119.306239,26.075302]}],
              [{coord:[117.190182,39.125596]},{coord:[108.320004,22.82402]}],
              [{coord:[117.190182,39.125596]},{coord:[113.280637,23.125178]}],
              [{coord:[117.190182,39.125596]},{coord:[102.712251,25.040609]}],
              [{coord:[117.190182,39.125596]},{coord:[126.642464,45.756967]}],
              [{coord:[117.190182,39.125596]},{coord:[125.3245,43.886841]}],
              [{coord:[117.190182,39.125596]},{coord:[111.670801,40.818311]}],
              [{coord:[117.190182,39.125596]},{coord:[87.617733,43.792818]}],
            ]
          },
          {
          name: '智源科技',
            type: 'effectScatter',
            coordinateSystem: 'geo',
            zlevel: 2,
            rippleEffect: {
              brushType: 'stroke'
            },
            label: {
              normal: {
                show: false,
                color: "#FFFF00",
                position: 'bottom',
                formatter: '{b}'
              }
            },
            symbolSize: function (val) {
              return val[2] / 8;
            },
            itemStyle: {
              normal: {
                color: "#FFFF00"
              }
            },
          // type: 'map',
          // mapType: 'china',

          // coordinateSystem: 'geo',
          // zlevel: 2,
          // rippleEffect: {
          //   brushType: 'stroke'
          // },
          // symbolSize: function (val) {
          //   return val[2] / 8;
          // },

          // select: {
          //   label: {show: false},
          //   itemStyle: {
          //     areaColor:'#031525'
          //   }
          // },
          data: [
            {name:'天津市',value:[117.190182,39.125596, 80]},
            {name:'北京市',value:[116.405285,39.904989, 80]},
            {name:'河北省',value:[114.502461,38.045474, 80]},

            {name:'山西省',value:[112.549248,37.857014, 80]},
            {name:'山东省',value:[117.000923,36.675807, 80]},
            {name:'河南省',value:[113.665412,34.757975, 80]},
            {name:'江苏省',value:[118.767413,32.041544, 80]},
            {name:'上海市',value:[121.472644,31.231706, 80]},
            {name:'浙江省',value:[120.153576,30.287459, 80]},
            {name:'宁夏回族自治区',value:[106.278179,38.46637, 80]},
            {name:'四川省',value:[104.065735,30.659462, 80]},
            {name:'湖南省',value:[112.982279,28.19409, 80]},
            {name:'贵州省',value:[106.713478,26.578343, 80]},
            {name:'福建省',value:[119.306239,26.075302, 80]},
            {name:'广西壮族自治区',value:[108.320004,22.82402, 80]},
            {name:'广东省',value:[113.280637,23.125178, 80]},
            {name:'云南省',value:[102.712251,25.040609, 80]},
            {name:'黑龙江省',value:[126.642464,45.756967, 80]},
            {name:'吉林省',value:[125.3245,43.886841, 80]},
            {name:'内蒙古自治区',value:[111.670801,40.818311, 80]},
            {name:'新疆维吾尔自治区',value:[87.617733,43.792818, 80]},
          ]
        }]
      };
      this.myChart.clear();
      this.myChart.setOption(this.option);
      setTimeout(() => { //解决:刷新界面后图形显示不全问题
        this.myChart.resize();
      }, 100)
      window.addEventListener("resize", () => {
        this.myChart.resize();
      });
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => {
      this.myChart.resize();
    });
  }
}
</script>
<style lang="scss" scoped>
.mapChart{
  width: 550px;
  height: 488px;
  display: inline-block;
}
</style>

